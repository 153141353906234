import { Location } from '@angular/common';
import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
declare const ResizeObserver: any;
@Directive({
  selector: '[appScrollAnchorContainer]',
  exportAs: 'ScrollAnchorContainer'
})
export class ScrollAnchorContainerDirective implements OnInit, OnDestroy {
  private scrollSub: Subscription;
  private anchors: Set<HTMLElement> = new Set();
  private currentFragment: string = undefined;
  private target: HTMLElement;
  @Output()
  public fragmentChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    private readonly location: Location,
  ) {
    const target = document.createElement('div');
    target.style.width = '100%';
    target.style.left = '0';
    target.style.height = '100px';
    target.style.position = 'fixed';
    this.target = target;
    this.elementRef.nativeElement.prepend(target);
    this.scrollSub = fromEvent(document, 'scroll').subscribe(() => {
      this.onscroll(target);
    });
  }

  public ngOnInit() {

  }

  private onscroll(target: HTMLElement) {
    const anchor = this.findIntersect(target);
    const fragment = anchor ? anchor.getAttribute('id') : undefined;
    if (this.currentFragment !== fragment) {
      this.currentFragment = fragment;
      const url = this.router.createUrlTree(['.'], {
        fragment,
        relativeTo: this.activeRoute
      });
      this.location.go(url.toString());
      this.fragmentChange.emit(fragment);
    }
  }

  private findIntersect(target: HTMLElement): HTMLElement {
    const targetRect = target.getBoundingClientRect();
    for (const anchor of this.anchors) {
      const anchorRect = anchor.getBoundingClientRect();

      const aLeftOfB = anchorRect.right < targetRect.left;
      const aRightOfB = anchorRect.left > targetRect.right;
      const aAboveB = anchorRect.top > targetRect.bottom;
      const aBelowB = anchorRect.bottom < targetRect.top;

      if (!(aLeftOfB || aRightOfB || aAboveB || aBelowB)) {
        return anchor;
      }
    }
  }

  public registerScrollAnchor(element: HTMLElement) {
    this.anchors.add(element);
  }

  public removeScrollAnchor(element: HTMLElement) {
    this.anchors.delete(element);
  }

  public ngOnDestroy() {
    this.scrollSub.unsubscribe();
  }

  public scrollTo(fragment: string, scrollOffset: number = 0) {
    const anchor = Array.from(this.anchors).find(_ => _.getAttribute('id') === fragment);
    if (anchor) {
      const targetRect = this.target.getBoundingClientRect();
      const anchorRect = anchor.getBoundingClientRect();
      const topDiff = (anchorRect.top - targetRect.top);
      document.scrollingElement.scrollTop += topDiff - scrollOffset;
    }
  }

}
