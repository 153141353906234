import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { ScrollAnchorContainerDirective } from '../scroll-anchor-container/scroll-anchor-container.directive';

@Directive({
  selector: '[appScrollAnchor]'
})
export class ScrollAnchorDirective implements OnDestroy {

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly container: ScrollAnchorContainerDirective
  ) {
    container.registerScrollAnchor(elementRef.nativeElement);
  }

  public ngOnDestroy() {
    this.container.removeScrollAnchor(this.elementRef.nativeElement);
  }

}
