// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ValueProvider } from '@angular/core';
import { BASE_URL as CHAT_BASE_URL } from '../app/shared/chatwoot/chatwoot.service';
import { BASE_PATH } from '../app/data';

export const environment = {
  production: false,
  trackingId: 2,
  showTradeshowTimingMockButton: true,
  appProviders: [
    {
      provide: CHAT_BASE_URL,
      useValue: 'https://chat-dev.idealsupply.com',
    } as ValueProvider,
    {
      provide: BASE_PATH,
      useValue: 'https://webservices-dev.idealsupply.com/vendor/etradeshow'
    } as ValueProvider,
    {
      provide: BASE_PATH,
      useValue: 'https://webservices-dev.idealsupply.com/vendor/etradeshow'
    } as ValueProvider,

  ],
};


/*{
    "id": 24,
    "name": "2023 Fall Automotive Show",
    "type": "Automotive",
    "startDate": "2023-09-18T11:30:00.000Z",
    "endDate": "2023-09-23T01:30:00.000Z",
    "rewardsOpenDate": "2023-09-11T12:00:00.000Z",
    "rewardsCloseDate": "2023-09-27T21:00:00.000Z",
    "siteFeatures": [
        {
            "id": 3,
            "name": "Automotive",
            "image": "https://webservices.idealsupply.com/images/tradeshow/logos/automotive.jpg",
            "fullWidth": true
        },
        {
            "id": 7,
            "name": "Tools",
            "image": "https://webservices.idealsupply.com/images/tradeshow/logos/tools.jpg",
            "fullWidth": false
        },
        {
            "id": 8,
            "name": "Safety",
            "image": "https://webservices.idealsupply.com/images/tradeshow/logos/safety.jpg",
            "fullWidth": false
        }
    ]
}*/
/*
{
  "id": 24,
  "name": "2023 Fall Automotive Show",
  "type": "Automotive",
  "startDate": "2023-09-18T11:30:00.000Z",
  "endDate": "2023-09-23T01:30:00.000Z",
  "rewardsOpenDate": "2023-09-11T12:00:00.000Z",
  "rewardsCloseDate": "2023-09-27T21:00:00.000Z",
  "showRewards": true,
  "showTraining": false,
  "siteFeatures": [
      {
          "id": 3,
          "name": "Automotive",
          "image": "https://webservices.idealsupply.com/images/tradeshow/logos/automotive.jpg",
          "fullWidth": true
      },
      {
          "id": 7,
          "name": "Tools",
          "image": "https://webservices.idealsupply.com/images/tradeshow/logos/tools.jpg",
          "fullWidth": false
      },
      {
          "id": 8,
          "name": "Safety",
          "image": "https://webservices.idealsupply.com/images/tradeshow/logos/safety.jpg",
          "fullWidth": false
      }
  ]
}*/
